.full-page-map {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
}

.full-page-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000; /* Make sure this is above the map */
  pointer-events: none; /* So the map interactions still work */
}

.container {
  position: relative;
  height: 100vh;
  width: 100vw;
}

.legend-container {
  position: absolute;
  top: 20px;
  right: 20px;
  bottom: 30px;
  z-index: 1001;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px;
  border-radius: 5px;
  width: 250px;
}

.legend-title {
  font-weight: bold;
  margin-bottom: 10px; /* Optional spacing */
}
